import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { createOrganizationDetailsTabPlugin } from '@mp/business-intelligence/analytics/bi-settings-edit/feature';
import { BIAnalyticsPermissions } from '@mp/business-intelligence/analytics/feature';
import { createUserDetailsTabPlugin } from '@mp/business-intelligence/user-settings/feature';
import { provideModuleInfo } from '@mp/shared/util';
import { provideModulePermissions } from '@mpk/shared/data-access';
import { ORGANIZATION_DETAILS_TAB_PLUGIN, USER_DETAILS_TAB_PLUGIN } from '@mpk/shared/util';

const moduleName = 'BusinessIntelligence';

export function provideBusinessIntelligenceFeature(): EnvironmentProviders[] {
  return [
    provideModuleInfo(moduleName, {
      displayName: 'Business Intelligence',
    }),
    provideModulePermissions(moduleName, BIAnalyticsPermissions),
    makeEnvironmentProviders([
      { provide: ORGANIZATION_DETAILS_TAB_PLUGIN, multi: true, useFactory: createOrganizationDetailsTabPlugin },
      { provide: USER_DETAILS_TAB_PLUGIN, multi: true, useFactory: createUserDetailsTabPlugin },
    ]),
  ];
}
