import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Signal, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { ArticleSearchPermissions } from '@mp/content-manager/artikelsuche/feature';
import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';
import { LoginContextService } from '@mpk/shared/data-access';

import { ArticleCounts } from '../../../models';
import { ContentManagementWidgetInsightsEvent } from '../../insights';
import { ArticleCountsBaseWidgetComponent } from '../article-counts-base-widget/article-counts-base-widget.component';

import { ContentManagerWidgetStore } from './content-management-widget.store';

@Component({
  selector: 'mpcm-content-management-widget',
  standalone: true,
  templateUrl: './content-management-widget.component.html',
  styleUrl: './content-management-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, ArticleCountsBaseWidgetComponent],
  providers: [ContentManagerWidgetStore],
})
export class ContentManagementWidgetComponent {
  private readonly loginContextService = inject(LoginContextService);

  protected readonly hasUseArticleSearchPermission: Signal<boolean> = this.loginContextService.permission(
    ArticleSearchPermissions.UseArticleSearch,
  );

  readonly articleCounts$: Observable<ArticleCounts | undefined> = this.store.articleCounts$;

  constructor(
    private readonly store: ContentManagerWidgetStore,
    private readonly insightsEventsTrackingService: InsightsEventsTrackingService,
  ) {
    this.store.fetchArticleCounts();
  }

  handleSearch(searchTerm: string): void {
    this.insightsEventsTrackingService.trackEvent(ContentManagementWidgetInsightsEvent.SEARCH);
    this.store.navigateToArticleSearch(searchTerm);
  }

  onFeaturePageNavigation(): void {
    this.insightsEventsTrackingService.trackEvent(ContentManagementWidgetInsightsEvent.FEATURE_QUICK_ACCESS);
  }
}
