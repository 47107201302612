import { ChangeDetectionStrategy, Component, HostBinding, Signal } from '@angular/core';

import { HeaderElementDirective } from '@core/shared/util';
import { RootModuleNavComponent } from '@core/ui';
import { DashboardComponent } from '@mp/dashboard/feature';
import { LoginContextService } from '@mpk/shared/data-access';
import { UserContext } from '@mpk/shared/domain';

@Component({
  selector: 'mp-landing-page',
  standalone: true,
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DashboardComponent, HeaderElementDirective, RootModuleNavComponent],
})
export class LandingPageComponent {
  @HostBinding('class') readonly classes = 'mp-landing-page';

  constructor(loginContextService: LoginContextService) {
    this.userContext = loginContextService.userContext;
  }

  protected readonly userContext: Signal<UserContext>;
}
