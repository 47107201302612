import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { DashboardService, MyOrganizationWidgetData } from '@mp/dashboard/data-access';

@Injectable()
export class DashboardFacade {
  private readonly _myOrganizationWidgetData$ = new BehaviorSubject(undefined as MyOrganizationWidgetData | undefined);
  readonly myOrganizationWidgetData$ = this._myOrganizationWidgetData$.asObservable();

  constructor(private readonly dashboardService: DashboardService) {}

  loadAllWidgetData(): void {
    this.getMyOrganizationWidgetData();
  }

  getMyOrganizationWidgetData(): void {
    this.dashboardService
      .getCurrentOrganizationUsersCount()
      .pipe(map((usersCount) => ({ usersCount }) as MyOrganizationWidgetData))
      .subscribe({ next: (widgetData) => this._myOrganizationWidgetData$.next(widgetData) });
  }
}
