import { EnvironmentProviders } from '@angular/core';

import { MedicalShopPermissions } from '@mp/medical-shop-2/core';
import { provideModuleInfo } from '@mp/shared/util';
import { provideModulePermissions } from '@mpk/shared/data-access';

const moduleName = 'MedicalShop';

export function provideMedicalShopFeature(): EnvironmentProviders[] {
  return [
    provideModuleInfo(moduleName, {
      displayName: 'Medical Shop',
    }),
    provideModulePermissions(moduleName, MedicalShopPermissions),
  ];
}
