import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { UploadEntryStatus } from '../../models';

@Component({
  selector: 'mpcm-datasource-upload-list-item-actions-menu',
  standalone: true,
  templateUrl: './datasource-upload-list-item-actions-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, MatButtonModule, MatMenuModule, MatIconModule, MatTooltipModule],
})
export class DatasourceUploadListItemActionsMenuComponent {
  @Input() status!: UploadEntryStatus;

  @Output()
  readonly fileDelete: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  readonly fileStatusChange: EventEmitter<UploadEntryStatus> = new EventEmitter<UploadEntryStatus>();

  @Output()
  readonly fileAttach: EventEmitter<void> = new EventEmitter<void>();

  readonly UploadEntryStatus = UploadEntryStatus;

  deleteFile(): void {
    this.fileDelete.emit();
  }

  changeFileStatus(status: UploadEntryStatus): void {
    this.fileStatusChange.emit(status);
  }

  attachFile(): void {
    this.fileAttach.emit();
  }
}
