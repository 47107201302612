import { Injectable, inject } from '@angular/core';

import { AbstractLinkProviderService } from '@mp/shared/util';
import { LoginContextService } from '@mpk/shared/data-access';
import { PlatformSuppliersPermissions } from '@mpsm/feature';

@Injectable({ providedIn: 'root' })
export class LinkProviderService extends AbstractLinkProviderService {
  private readonly loginContextService = inject(LoginContextService);

  override tryGetLinkToPlatformSupplier(supplierId: string): string | undefined {
    if (
      this.loginContextService.permissionContext().hasPermission(PlatformSuppliersPermissions.ViewPlatformSuppliers)
    ) {
      return `/lieferanten-management/lieferanten/${supplierId}`;
    }

    return undefined;
  }
}
