<ng-container *ngIf="(isNewsListLoading$ | async) === false; else loading">
  <div *ngIf="newsList$ | async as newsList" class="news-list">
    <ng-container *ngIf="hasCreateNewsForOwnOrganizationPermission()">
      <mp-create-news-bar
        *ngIf="!addingNewsFormVisible; else addingNewsForm"
        (createNewsClick)="toggleAddingNewsFormVisible()"
      />

      <ng-template #addingNewsForm>
        <mp-news-item-edit-container [newsItem]="undefined" (editModeClose)="toggleAddingNewsFormVisible()" />
      </ng-template>
    </ng-container>

    <ng-container *ngIf="newsList.length > 0; else noNews">
      <ng-container *ngFor="let newsItem of newsList; trackBy: trackByFn">
        <mp-news-item-edit-container
          *ngIf="newsEditMap[newsItem.newsItemId]; else readNewsMode"
          [newsItem]="newsItem"
          (editModeClose)="toggleNewsItemEditMode(newsItem.newsItemId)"
        />

        <ng-template #readNewsMode>
          <mp-news-item
            [newsItem]="newsItem"
            [expanded]="expandedNewsMap[newsItem.newsItemId]"
            (expandedChange)="updateNewsItemExpansionState(newsItem.newsItemId, $event)"
            (editModeChange)="toggleNewsItemEditMode(newsItem.newsItemId)"
          />
        </ng-template>
      </ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="newsListPaginationMetadata$ | async as pagination">
    <mp-paginator *ngIf="pagination.totalRowCount > 10" [pagination]="pagination" (page)="onPageChange($event)" />
  </ng-container>
</ng-container>

<ng-template #noNews>
  <p>Keine neuen Nachrichten.</p>
</ng-template>

<ng-template #loading>
  <mp-spinner />
</ng-template>
