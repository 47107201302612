import { permissions } from '@mpk/shared/domain';

/**
 * Permissions for the BI
 */
enum BIAnalyticsPermissionsEnum {
  None = 0,

  /**
   * The user gets access to BIAnalytics in the main menu
   */
  UseBI = 1,

  /**
   * Manage BI Analytics settings for users and organizations.
   */
  ManageBISettings = 2,
}

export const BIAnalyticsPermissions = permissions(BIAnalyticsPermissionsEnum, {
  featureId: 'BIAnalytics',
});
