import { type EnvironmentInjector, inject } from '@angular/core';

import { type LazyComponentDetails } from '@core/shared/util';
import { LoginContextService } from '@mpk/shared/data-access';
import { type SettingsFormPlugin, type SettingsFormPluginComponent } from '@mpk/shared/util';

import { Amor3ExportPermissions } from '../permissions';

/**
 * Factory to create the AMOR3 export settings plugin.
 */
export function createAmor3ExportSettingsPlugin(): SettingsFormPlugin {
  const loginContextService = inject(LoginContextService);

  return {
    settingsId: 'Amor3ExportClientSettings',
    displayName: 'AMOR3 Materialstamm Export',
    description: 'API-Konfiguration des AMOR3 Materialstamm Services',
    isEnabled: loginContextService.permission(Amor3ExportPermissions.ManageSettings),
    async getComponentDetails(
      parentInjector: EnvironmentInjector,
    ): Promise<LazyComponentDetails<SettingsFormPluginComponent>> {
      const { Amor3ExportSettingsEditComponent } = await import('../components');

      return {
        component: Amor3ExportSettingsEditComponent,
        environmentInjector: parentInjector,
      };
    },
  };
}
