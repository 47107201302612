<mat-card class="news-edit" [formGroup]="newsItemEditDataForm">
  <div class="news-edit-header">
    <span class="news-edit-header__title">
      <mat-icon class="news-edit-header__title-icon" svgIcon="document_edit" />
      <span class="news-edit-header__title-content truncate" title="Nachricht verfassen">Nachricht verfassen</span>
    </span>

    <mp-news-item-edit-actions
      [disabled]="!newsItemEditDataForm.valid"
      [showDeleteAction]="!!newsItem"
      tooltipText="Bitte gebe eine Überschrift und eine Nachricht an."
      (saveClick)="saveNews()"
      (closeClick)="closeEditMode()"
      (deleteClick)="deleteNews()"
    />
  </div>

  <mat-divider class="news-edit__divider" />

  <mat-form-field appearance="fill">
    <mat-label>Überschrift</mat-label>
    <input
      matInput
      formControlName="title"
      required
      placeholder="Überschrift eingeben..."
      [maxlength]="TITLE_MAX_LENGTH"
    />
    <mat-error *ngIf="titleControl.errors && titleControl.touched">
      <span>{{ titleControl.errors | mpNewsItemTitleErrorMessage }}</span>
    </mat-error>
  </mat-form-field>

  <div class="description-form-field">
    <mp-rich-text-editor
      formControlName="description"
      placeholder="Nachricht eingeben..."
      [required]="true"
      [maxLength]="DESCRIPTION_TEXT_CONTENT_MAX_LENGTH"
    />
    <mat-error *ngIf="descriptionControl.errors && descriptionControl.touched" class="description-form-field__error">
      <span>{{ descriptionControl.errors | mpNewsItemDescriptionErrorMessage }}</span>
    </mat-error>
  </div>

  <mp-news-organisations-selector
    *ngIf="showOrganisationsSelector"
    [(selectedOrganisations)]="selectedOrganisations"
    [availableOrganisations]="availableOrganisations"
  />
</mat-card>
