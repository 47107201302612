import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, untracked } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';

import { DashboardService, MyOrganizationWidgetData } from '@mp/dashboard/data-access';
import { NewsfeedComponent } from '@mp/dashboard/newsfeed/feature';
import { DashboardWidgetComponent } from '@mp/shared/ui/dashboard-widget';
import { ArticleSearchPermissions, UploadPermissions } from '@mpcm/feature';
import { ContentManagementDashboardModule, DatasourceUploadWidgetModule } from '@mpcm/shared';
import { PurchaseRequisitionsPermissions } from '@mpdm/feature';
import { DocumentManagementDashboardModule } from '@mpdm/shared';
import { IdentityUsersPermissions } from '@mpk/feature';
import { LoginContextService } from '@mpk/shared/data-access';
import { MedicalShopPermissions } from '@mpms/feature';
import { MedicalShopDashboardModule } from '@mpms/shared';

import { DashboardFacade } from './dashboard.facade';
import { MyOrganizationWidgetComponent } from './widgets/my-organization/my-organization-widget.component';

@Component({
  selector: 'mp-dashboard',
  standalone: true,
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,

    MatIconModule,

    DatasourceUploadWidgetModule,
    DashboardWidgetComponent,
    ContentManagementDashboardModule,
    DocumentManagementDashboardModule,
    MedicalShopDashboardModule,
    MyOrganizationWidgetComponent,
    NewsfeedComponent,
  ],
  providers: [DashboardFacade, DashboardService],
})
export class DashboardComponent {
  readonly myOrganizationWidgetData$: Observable<MyOrganizationWidgetData | undefined> =
    this.facade.myOrganizationWidgetData$;

  // TODO: Either this component should be host stuff or the widgets should come from a plugin approach
  //       and check the permissions within the module itself.

  protected readonly hasManageOrganizationUsersPermission = this.loginContextService.permission(
    IdentityUsersPermissions.ManageOrganizationUsers,
  );
  protected readonly hasUseUploadPermission = this.loginContextService.permission(UploadPermissions.UseUpload);
  protected readonly hasUseArticleSearchPermission = this.loginContextService.permission(
    ArticleSearchPermissions.UseArticleSearch,
  );
  protected readonly hasApproveOwnPurchaseRequisitionsPermission = this.loginContextService.permission(
    PurchaseRequisitionsPermissions.ApproveOwnPurchaseRequisitions,
  );
  protected readonly hasUseMedicalShopPermission = this.loginContextService.permission(
    MedicalShopPermissions.UseMedicalShop,
  );

  constructor(
    private readonly facade: DashboardFacade,
    private readonly loginContextService: LoginContextService,
  ) {
    effect(() => {
      if (this.hasManageOrganizationUsersPermission()) {
        untracked(() => this.facade.loadAllWidgetData());
      }
    });
  }
}
