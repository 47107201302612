import { DeploymentSettingsPermissions } from '@mp/system/deployment-settings/feature';
import { PlatformPackagingUnitsPermissions } from '@mp/system/platform-packaging-units/feature';
import { BIAnalyticsPermissions } from '@mpbi/feature';
import {
  ArticleMatcherPermissions,
  ArticleRangesPermissions,
  ArticleSearchPermissions,
  ClinicCatalogsPermissions,
  IndustryCatalogsPermissions,
  PlatformCatalogsPermissions,
} from '@mpcm/feature';
import { ConditionsPermissions, ContractsPermissions } from '@mpctm/feature';
import {
  ApiKeysPermissions,
  IdentityUsersPermissions,
  OrganizationsPermissions,
  hasAnyEnabledOrganizationSetting,
} from '@mpk/feature';
import { MedicalShopPermissions } from '@mpms/feature';
import { AddressesPermissions, CostCentersPermissions } from '@mpomd/feature';
import { ClinicSuppliersPermissions, PlatformSuppliersPermissions } from '@mpsm/feature';

import { NavigationStructure } from './navigation-item';

/* eslint-disable @nx/enforce-module-boundaries */
export const navigationStructure: NavigationStructure = [
  {
    label: 'Analytics',
    route: 'analytics',
    icon: 'insert_chart',
    permissions: [BIAnalyticsPermissions.UseBI],
    loadChildRoutes: async () => (await import('@mpbi/routes')).BUSINESS_INTELLIGENCE_ANALYTICS_ROUTES,
  },
  {
    label: 'Artikelsuche',
    route: 'artikel',
    icon: 'search',
    permissions: [ArticleSearchPermissions.UseArticleSearch],
    loadChildRoutes: () =>
      import('libs/mediio-plattform/content-manager/artikelsuche/feature/src/lib/artikelsuche.module').then(
        (mod) => mod.ArtikelsucheModule,
      ),
  },
  {
    label: 'Belege',
    icon: 'list_alt',
    route: 'orders',
    children: [
      {
        label: 'Anforderungen',
        route: 'document-management',
        icon: 'fact_check',
        // This really has no permission but only makes sense if one can create purchase requisitions.
        permissions: [MedicalShopPermissions.UseMedicalShop],
        loadChildRoutes: () =>
          import('libs/mediio-plattform/document-management/core/src/lib/document-management-core.module').then(
            (mod) => mod.DocumentManagementCoreModule,
          ),
      },
    ],
  },
  {
    label: 'Content Management',
    icon: 'document_scanner',
    route: 'content-management',
    children: [
      {
        label: 'Matchvorschläge',
        route: 'match-proposals',
        icon: 'link',
        permissions: [ArticleMatcherPermissions.ManageArticleMatches],
        loadChildRoutes: () =>
          import('@mp/content-manager/article-matching/match-proposals/routes').then(
            (mod) => mod.MATCH_PROPOSALS_ROUTES,
          ),
      },
    ],
  },
  {
    label: 'Katalog Management',
    icon: 'menu_book',
    route: 'katalog-management',
    children: [
      {
        label: 'Klinikkataloge',
        route: 'klinikkataloge',
        icon: 'menu_book',
        permissions: [ClinicCatalogsPermissions.ManageClinicCatalogs],
        loadChildRoutes: () =>
          import('libs/mediio-plattform/content-manager/klinikkataloge/feature/src/lib/klinikkataloge.module').then(
            (mod) => mod.KlinikkatalogeModule,
          ),
      },
      {
        label: 'Industriekataloge',
        route: 'kataloge',
        icon: 'menu_book',
        permissions: [IndustryCatalogsPermissions.ViewIndustryCatalogs],
        loadChildRoutes: () =>
          import(
            'libs/mediio-plattform/content-manager/industriekataloge/feature/src/lib/industriekataloge.module'
          ).then((mod) => mod.IndustriekatalogeModule),
      },
      {
        label: 'Plattformkataloge',
        route: 'plattformkataloge',
        icon: 'menu_book',
        permissions: [PlatformCatalogsPermissions.ViewPlatformCatalogs],
        loadChildRoutes: () =>
          import(
            'libs/mediio-plattform/content-manager/plattformkataloge/feature/src/lib/plattformkataloge.module'
          ).then((mod) => mod.PlattformkatalogeModule),
      },
      {
        label: 'Sortimente',
        route: 'article-ranges',
        icon: 'inventory_2',
        permissions: [ArticleRangesPermissions.ManageArticleRanges],
        loadChildRoutes: () =>
          import('libs/mediio-plattform/content-manager/core/src/lib/articles/ranges/article-ranges.module').then(
            (mod) => mod.ArticleRangesModule,
          ),
      },
    ],
  },
  {
    label: 'Lieferanten Management',
    icon: 'local_shipping',
    route: 'lieferanten-management',
    children: [
      {
        label: 'Kliniklieferanten',
        route: 'kliniklieferanten',
        icon: 'local_shipping',
        permissions: [ClinicSuppliersPermissions.ViewClinicSuppliers],
        loadChildRoutes: async () => (await import('@mpsm/routes')).SUPPLIER_MANAGEMENT_CLINIC_SUPPLIERS_ROUTES,
      },
      {
        label: 'Plattformlieferanten',
        route: 'lieferanten',
        icon: 'local_shipping',
        permissions: [PlatformSuppliersPermissions.ViewPlatformSuppliers],
        loadChildRoutes: async () => (await import('@mpsm/routes')).SUPPLIER_MANAGEMENT_PLATFORM_SUPPLIERS_ROUTES,
      },
    ],
  },
  {
    label: 'Vertragsmanagement',
    icon: 'history_edu',
    route: 'contract-management',
    children: [
      {
        label: 'Konditionen',
        route: 'conditions',
        icon: 'request_quote',
        permissions: [ConditionsPermissions.ViewConditions],
        loadChildRoutes: async () => (await import('@mpctm/routes')).CONTRACT_MANAGEMENT_CONDITIONS_ROUTES,
      },
      {
        label: 'Verträge',
        route: 'contracts',
        icon: 'receipt_long',
        permissions: [ContractsPermissions.ViewContracts],
        loadChildRoutes: async () => (await import('@mpctm/routes')).CONTRACT_MANAGEMENT_CONTRACTS_ROUTES,
      },
    ],
  },
  {
    label: 'Medical Shop',
    route: 'medical-shop2',
    icon: 'store',
    permissions: [MedicalShopPermissions.UseMedicalShop],
    loadChildRoutes: () =>
      import('libs/mediio-plattform/medical-shop-2/core/src/lib/medical-shop-core.module').then(
        (mod) => mod.MedicalShopCoreModule,
      ),
  },
  {
    label: 'Meine Organisation',
    icon: 'home_work',
    route: 'organisation',
    children: [
      {
        label: 'Adressen',
        route: 'adressen',
        icon: 'location_on',
        permissions: [AddressesPermissions.ManageAllAddresses],
        loadChildRoutes: async () => (await import('@mpomd/routes')).ORGANIZATION_MASTER_DATA_ADDRESSES_ROUTES,
      },
      {
        label: 'Kostenstellen',
        route: 'kostenstellen',
        icon: 'account_balance_wallet',
        permissions: [CostCentersPermissions.ManageAllCostcenters],
        loadChildRoutes: async () => (await import('@mpomd/routes')).ORGANIZATION_MASTER_DATA_COST_CENTERS_ROUTES,
      },
      {
        label: 'API Keys',
        route: 'api-keys',
        icon: 'key',
        permissions: [ApiKeysPermissions.ManageApiKeys],
        loadChildRoutes: async () => (await import('@mpk/routes')).KERNEL_API_KEYS_ROUTES,
      },
      {
        label: 'Benutzer',
        route: 'benutzer',
        icon: 'people',
        permissions: [IdentityUsersPermissions.ManageOrganizationUsers],
        loadChildRoutes: async () => (await import('@mpk/routes')).KERNEL_CURRENT_ORGANIZATION_USERS_ROUTES,
      },
      {
        label: 'Rollen',
        route: 'rollen',
        icon: 'policy',
        permissions: [IdentityUsersPermissions.ManageOrganizationUsers],
        loadChildRoutes: async () => (await import('@mpk/routes')).KERNEL_CURRENT_ORGANIZATION_ROLES_ROUTES,
      },
      {
        label: 'Einstellungen',
        route: 'settings',
        icon: 'settings',
        isEnabled: hasAnyEnabledOrganizationSetting,
        loadChildRoutes: async () => (await import('@mpk/routes')).KERNEL_ORGANIZATION_SETTINGS_ROUTES,
      },
      {
        label: 'Info',
        route: 'info',
        icon: 'info',
        loadChildRoutes: () =>
          import('libs/mediio-plattform/my-organization/info/feature/src/lib/organization-info.module').then(
            (mod) => mod.OrganizationInfoModule,
          ),
      },
    ],
  },
  {
    label: 'System',
    icon: 'settings',
    route: 'system',
    children: [
      {
        label: 'Benutzer',
        route: 'benutzer',
        icon: 'people',
        permissions: [IdentityUsersPermissions.ManageAllUserAndRoles],
        loadChildRoutes: async () => (await import('@mpk/routes')).KERNEL_USERS_ROUTES,
      },
      {
        label: 'Rollen',
        route: 'rollen',
        icon: 'policy',
        permissions: [IdentityUsersPermissions.ManageAllUserAndRoles],
        loadChildRoutes: async () => (await import('@mpk/routes')).KERNEL_ROLES_ROUTES,
      },
      {
        label: 'Organisationen',
        route: 'organisationen',
        icon: 'account_balance',
        permissions: [OrganizationsPermissions.ManageOrganizations],
        loadChildRoutes: async () => (await import('@mpk/routes')).KERNEL_ORGANIZATIONS_ROUTES,
      },
      {
        label: 'Deployment Einstellungen',
        route: 'deployment-settings',
        icon: 'support',
        permissions: [DeploymentSettingsPermissions.ManageSupportSettings],
        loadChildRoutes: async () => (await import('@mp/system/deployment-settings/routes')).DEPLOYMENT_SETTINGS_ROUTES,
      },
      {
        label: 'Plattformeinheiten',
        route: 'platform-packaging-units',
        icon: 'straighten',
        permissions: [PlatformPackagingUnitsPermissions.ManagePlatformPackagingUnits],
        loadChildRoutes: () =>
          import(
            'libs/mediio-plattform/system/platform-packaging-units/feature/src/lib/platform-packaging-units.module'
          ).then((mod) => mod.PlatformPackagingUnitsModule),
      },
      {
        label: 'Info',
        route: 'info',
        icon: 'info',
        loadChildRoutes: async () => (await import('@mp/system/info/feature')).INFO_ROUTES,
      },
    ],
  },
];
