import { EnvironmentInjector, inject } from '@angular/core';

import { LazyComponentDetails } from '@core/shared/util';
import { BIAnalyticsPermissions } from '@mp/business-intelligence/analytics/feature';
import { ComponentPluginComponent } from '@mp/shared/component-plugins/util';
import { LoginContextService } from '@mpk/shared/data-access';
import type { CurrentOrganizationUserDetailsTabPluginInput, IUserDetailsTabPlugin } from '@mpk/shared/util';

export function createUserDetailsTabPlugin(): IUserDetailsTabPlugin {
  const loginContextService = inject(LoginContextService);

  return {
    label: 'Business Intelligence',
    isEnabled: loginContextService.permission(BIAnalyticsPermissions.ManageBISettings),

    async getComponentDetails(
      parentInjector: EnvironmentInjector,
    ): Promise<LazyComponentDetails<ComponentPluginComponent<CurrentOrganizationUserDetailsTabPluginInput>>> {
      const biUserSettingsFeature = await import('../components');

      return {
        component: biUserSettingsFeature.UserBiSettingsEditComponent,
        environmentInjector: parentInjector,
      };
    },
  };
}
