@if (articleCounts$ | async; as articleCounts) {
  <mpcm-article-counts-base-widget
    title="Artikelsuche"
    icon="manage_search"
    [featurePageLink]="['/artikel']"
    [data]="[
      { label: 'Industrieartikel', count: articleCounts.industryArticleCount },
      { label: 'Klinikartikel', count: articleCounts.clinicArticleCount }
    ]"
    [enabledByPermission]="hasUseArticleSearchPermission()"
    [noPermissionText]="'Das Modul Content Manager Artikelsuche steht dir nicht zur Verfügung.'"
    (searchTermChange)="handleSearch($event)"
    (featurePageNavigation)="onFeaturePageNavigation()"
  />
}
