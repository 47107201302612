import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { ContractsPermissions } from '@mp/contract-management/common/feature';
import { ConditionsPermissions } from '@mp/contract-management/conditions/feature';
import { createPlatformSupplierDetailsTabPlugin as createPlatformSupplierDetailsConditionsTabPlugin } from '@mp/contract-management/conditions/platform-suppliers/feature';
import { createPlatformSupplierDetailsTabPlugin as createPlatformSupplierDetailsContractsTabPlugin } from '@mp/contract-management/contracts/platform-suppliers/feature';
import { provideModuleInfo } from '@mp/shared/util';
import { provideModulePermissions } from '@mpk/shared/data-access';
import { PLATFORM_SUPPLIER_DETAILS_TAB_PLUGIN } from '@mpsm/shared/util';

const moduleName = 'ContractManagement';

export function provideContractManagementFeature(): EnvironmentProviders[] {
  return [
    provideModuleInfo(moduleName, {
      displayName: 'Vertragsmanagement',
    }),
    provideModulePermissions(moduleName, ConditionsPermissions, ContractsPermissions),
    makeEnvironmentProviders([
      {
        provide: PLATFORM_SUPPLIER_DETAILS_TAB_PLUGIN,
        multi: true,
        useFactory: createPlatformSupplierDetailsContractsTabPlugin,
      },
      {
        provide: PLATFORM_SUPPLIER_DETAILS_TAB_PLUGIN,
        multi: true,
        useFactory: createPlatformSupplierDetailsConditionsTabPlugin,
      },
    ]),
  ];
}
