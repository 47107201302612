import { Route } from '@angular/router';

import { NavigationGroup, NavigationItem, NavigationStructure } from './navigation-item';
import { redirectToFirstChild } from './redirect-to-first-child';
import { requirePermission } from './require-permission';

export class RouteBuilder {
  private errorRoute?: Route;

  private constructor(private readonly routes: Array<Route>) {}

  static fromRouterConfig(routerConfig: Array<Route>): RouteBuilder {
    return new RouteBuilder([...routerConfig]);
  }

  registerErrorRoute(errorRoute: Route): RouteBuilder {
    this.errorRoute = errorRoute;

    return this;
  }

  registerNavigation(navigationItems: NavigationStructure): RouteBuilder {
    navigationItems.forEach((navigationItem) => this.registerNavigationItem(navigationItem));

    return this;
  }

  buildRoutes(): Array<Route> {
    this.appendErrorRouteDefinitions();

    return this.routes;
  }

  private registerNavigationItem(navigationItem: NavigationGroup | NavigationItem): void {
    if ('children' in navigationItem) {
      this.routes.push(this.createGroupRoute(navigationItem));
    } else {
      this.routes.push(this.createItemRoute(navigationItem));
    }
  }

  private appendErrorRouteDefinitions(): void {
    if (this.errorRoute) {
      this.routes.push(this.errorRoute);
      this.routes.push({
        path: '**',
        redirectTo: this.errorRoute.path,
      });
    }
  }

  private createGroupRoute(navigationGroup: NavigationGroup): Route {
    const children: Route[] = [];

    const groupRoute: Route = {
      path: navigationGroup.route,
      children,
    };

    navigationGroup.children.forEach((item) => {
      const childRoute = this.createItemRoute(item);

      // This is legacy behavior of the active module name map
      // where only the first level of navigation items was considered for the title.
      childRoute.title = navigationGroup.label;

      children.push(childRoute);
    });

    children.push({
      path: '',
      pathMatch: 'full',
      children: [],
      title: navigationGroup.label,
      canActivate: [redirectToFirstChild(navigationGroup)],
    });

    return groupRoute;
  }

  private createItemRoute(navigationItem: NavigationItem): Route {
    const canActivate: Route['canActivate'] = [];

    const route: Route = {
      path: navigationItem.route,
      title: navigationItem.label,
      loadChildren: navigationItem.loadChildRoutes,
      canActivate,
    };

    if (navigationItem.permissions != null) {
      canActivate.push(requirePermission(...navigationItem.permissions));
    }

    if (navigationItem.isEnabled != null) {
      canActivate.push(navigationItem.isEnabled);
    }

    return route;
  }
}
