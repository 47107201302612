import { type EnvironmentProviders } from '@angular/core';

import {
  SapOdataPurchaseRequisitionsPermissions,
  createTransferPurchaseRequisitionClientSettingsPlugin,
} from '@mp/integrations/sap-odata/document-management/purchase-requisitions/feature';
import { provideModuleInfo } from '@mp/shared/util';
import { provideModuleOrganizationSettings, provideModulePermissions } from '@mpk/shared/data-access';

const moduleName = 'SapOdata';
export function provideSapOdataFeature(): EnvironmentProviders[] {
  return [
    provideModuleInfo(moduleName, {
      displayName: 'SAP OData',
    }),
    provideModulePermissions(moduleName, SapOdataPurchaseRequisitionsPermissions),
    provideModuleOrganizationSettings(moduleName, createTransferPurchaseRequisitionClientSettingsPlugin),
  ];
}
