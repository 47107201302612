<div class="dashboard row">
  <div class="widget-column column">
    <h2>Auf einen Blick</h2>

    @if (hasUseArticleSearchPermission()) {
      <mpcm-content-management-widget class="widget" />
    }

    @if (hasUseUploadPermission()) {
      <mpcm-datasource-upload-widget class="widget" />
    }

    @if (hasUseMedicalShopPermission()) {
      <mpms-medical-shop-widget class="widget" />
    }

    @if (hasApproveOwnPurchaseRequisitionsPermission()) {
      <mpdm-pending-requisition-counts-widget class="widget" />
    }

    @if (hasApproveOwnPurchaseRequisitionsPermission()) {
      <mpdm-requisitions-history-widget class="widget" />
    }

    @if (hasManageOrganizationUsersPermission()) {
      @if (myOrganizationWidgetData$ | async; as myOrganizationWidgetData) {
        <mp-my-organization-widget class="widget" [data]="myOrganizationWidgetData" />
      }
    }
  </div>

  <div class="news-column column">
    <h2 class="news-column__title">
      <mat-icon svgIcon="mdi:newspaper-variant-outline" />
      Aktuelles
    </h2>

    <mp-newsfeed />
  </div>
</div>
