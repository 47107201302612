import { NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';

import { ZendeskSupportFormDialogComponent } from './zendesk-support-form-dialog.component';

const DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
  panelClass: 'mp-zendesk-support-form-dialog-container',
};

@NgModule({
  imports: [ZendeskSupportFormDialogComponent],
  exports: [ZendeskSupportFormDialogComponent],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: DEFAULT_DIALOG_CONFIG }],
})
export class ZendeskSupportFormDialogModule {}
