import { Injectable } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, filter, map } from 'rxjs';

import { ViewModelSelector, filterUndefined } from '@core/shared/util';
import { FeedbackFacade } from '@mp/shared/helper-links/feedback/data-access';
import { FeedbackSettings, FeedbackType } from '@mp/shared/helper-links/feedback/domain';
import { MailSenderDetails } from '@mp/shared/helper-links/feedback/ui';
import { LoginContextService } from '@mpk/shared/data-access';
import { IdentityUserContext, UserType } from '@mpk/shared/domain';

import { FeedbackButtonViewModel } from './feedback-button-view-model';

@Injectable()
export class FeedbackButtonStore extends ComponentStore<Record<string, never>> {
  readonly mailSenderDetails$: Observable<MailSenderDetails> = toObservable(this.loginContextService.userContext).pipe(
    filter((userContext): userContext is IdentityUserContext => userContext.type === UserType.IdentityUser),
    map(({ firstName, lastName, email }) => ({
      userFirstName: firstName,
      userLastName: lastName,
      userEmail: email,
    })),
  );

  readonly feedbackSettings$: Observable<FeedbackSettings> =
    this.feedbackFacade.feedbackSettings$.pipe(filterUndefined());

  readonly vm$: Observable<FeedbackButtonViewModel> = this.select(
    {
      mailSenderDetails: this.mailSenderDetails$,
      feedbackSettings: this.feedbackSettings$,
      isZendeskEnabled: this.feedbackSettings$.pipe(map(({ activeType }) => activeType === FeedbackType.Zendesk)),
    } satisfies ViewModelSelector<FeedbackButtonViewModel>,
    { debounce: true },
  );

  constructor(
    private readonly feedbackFacade: FeedbackFacade,
    private readonly loginContextService: LoginContextService,
  ) {
    super();
  }
}
