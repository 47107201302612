import { EnvironmentProviders } from '@angular/core';

import { PurchaseRequisitionsPermissions } from '@mp/document-management/core';
import { provideModuleInfo } from '@mp/shared/util';
import { provideModulePermissions } from '@mpk/shared/data-access';

const moduleName = 'DocumentManagement';

export function provideDocumentManagementFeature(): EnvironmentProviders[] {
  return [
    provideModuleInfo(moduleName, {
      displayName: 'Belege',
    }),
    provideModulePermissions(moduleName, PurchaseRequisitionsPermissions),
  ];
}
