import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, map, startWith, tap } from 'rxjs';

import { ViewModelSelector, filterUndefined } from '@core/shared/util';
import { FeedbackFacade } from '@mp/shared/helper-links/feedback/data-access';
import { FeedbackSettings, FeedbackType } from '@mp/shared/helper-links/feedback/domain';
import { HelpCenterFacade } from '@mp/shared/helper-links/help-center/data-access';
import { HelpCenterSettings, HelpCenterType } from '@mp/shared/helper-links/help-center/domain';

import { HelperLinksContainerViewModel } from './helper-links-container-view-model';

@Injectable()
export class HelperLinksContainerStore extends ComponentStore<Record<string, never>> {
  private readonly isFeedbackEnabled$: Observable<boolean> = this.feedbackFacade.feedbackSettings$.pipe(
    filterUndefined(),
    map(({ activeType }: FeedbackSettings) => activeType !== FeedbackType.Disabled),
    startWith(false),
  );

  private readonly isZendeskFeedbackActive$: Observable<boolean> = this.feedbackFacade.feedbackSettings$.pipe(
    filterUndefined(),
    map(({ activeType }: FeedbackSettings) => activeType === FeedbackType.Zendesk),
    startWith(false),
  );

  private readonly isHelpCenterEnabled$: Observable<boolean> = this.helpCenterFacade.helpCenterSettings$.pipe(
    filterUndefined(),
    map(({ activeType }: HelpCenterSettings) => activeType !== HelpCenterType.Disabled),
    startWith(false),
  );

  readonly vm$: Observable<HelperLinksContainerViewModel> = this.select(
    {
      isFeedbackEnabled: this.isFeedbackEnabled$,
      isZendeskFeedbackActive: this.isZendeskFeedbackActive$,
      isHelpCenterEnabled: this.isHelpCenterEnabled$,
    } satisfies ViewModelSelector<HelperLinksContainerViewModel>,
    { debounce: true },
  );

  constructor(
    private readonly feedbackFacade: FeedbackFacade,
    private readonly helpCenterFacade: HelpCenterFacade,
  ) {
    super();
  }

  readonly fetchFeedbackSettings = this.effect((trigger$: Observable<void>): Observable<unknown> => {
    return trigger$.pipe(tap(() => this.feedbackFacade.fetchFeedbackSettings()));
  });

  fetchHelpCenterSettings(): void {
    this.helpCenterFacade.fetchHelpCenterSettings();
  }
}
