import { EnvironmentInjector, inject } from '@angular/core';

import { LazyComponentDetails } from '@core/shared/util';
import { CostCentersPermissions } from '@mp/organization-master-data/cost-centers/feature';
import { ComponentPluginComponent } from '@mp/shared/component-plugins/util';
import { LoginContextService } from '@mpk/shared/data-access';
import type {
  CurrentOrganizationUserDetailsTabPluginInput,
  ICurrentOrganizationUserDetailsTabPlugin,
} from '@mpk/shared/util';

export function createCurrentOrganizationUserDetailsTabPlugin(): ICurrentOrganizationUserDetailsTabPlugin {
  const loginContextService = inject(LoginContextService);

  return {
    label: 'Kostenstellen',
    isEnabled: loginContextService.permission(CostCentersPermissions.ManageAllCostcenters),

    async getComponentDetails(
      parentInjector: EnvironmentInjector,
    ): Promise<LazyComponentDetails<ComponentPluginComponent<CurrentOrganizationUserDetailsTabPluginInput>>> {
      const userCostCentersAssignmentFeature = await import('../components');

      return {
        component: userCostCentersAssignmentFeature.UserCostCentersAssignmentComponent,
        environmentInjector: parentInjector,
      };
    },
  };
}
