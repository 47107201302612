import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';

import { LoginContextService } from '@mpk/shared/data-access';
import { Permission } from '@mpk/shared/domain';

export function requirePermission(...permissions: Permission[]): CanActivateFn {
  return (): boolean | UrlTree => {
    const loginContextService = inject(LoginContextService);
    const router = inject(Router);

    const permissionContext = loginContextService.permissionContext();

    return (
      permissions.some((permission) => permissionContext.hasPermission(permission)) || router.createUrlTree(['error'])
    );
  };
}
