import { EnvironmentInjector, createEnvironmentInjector, inject } from '@angular/core';

import { LazyComponentDetails } from '@core/shared/util';
import { BIAnalyticsPermissions } from '@mp/business-intelligence/analytics/feature';
import { ComponentPluginComponent } from '@mp/shared/component-plugins/util';
import { LoginContextService } from '@mpk/shared/data-access';
import type { IOrganizationDetailsTabPlugin, OrganizationDetailsTabPluginInput } from '@mpk/shared/util';

export function createOrganizationDetailsTabPlugin(): IOrganizationDetailsTabPlugin {
  const loginContextService = inject(LoginContextService);

  return {
    label: 'Business Intelligence',
    isEnabled: loginContextService.permission(BIAnalyticsPermissions.ManageBISettings),

    async getComponentDetails(
      parentInjector: EnvironmentInjector,
    ): Promise<LazyComponentDetails<ComponentPluginComponent<OrganizationDetailsTabPluginInput>>> {
      const biSettingsEditFeature = await import('../pages');
      const biAnalyticsDataAccess = await import('@mp/business-intelligence/analytics/data-access');

      return {
        component: biSettingsEditFeature.BiAnalyticsContainerComponent,
        environmentInjector: createEnvironmentInjector(
          biAnalyticsDataAccess.provideBiAnalyticsDataAccess(),
          parentInjector,
        ),
      };
    },
  };
}
