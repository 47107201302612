import { permissions } from '@mpk/shared/domain';

enum SapOdataPurchaseRequisitionsPermissionsEnum {
  None = 0,

  /**
   * Manage the settings for the API to transfer purchase requisitions via the SapOdata client.
   */
  ManageSettings = 1,
}

export const SapOdataPurchaseRequisitionsPermissions = permissions(SapOdataPurchaseRequisitionsPermissionsEnum, {
  featureId: 'PurchaseRequisitions',
});
