import { type EnvironmentInjector, inject } from '@angular/core';

import { type LazyComponentDetails } from '@core/shared/util';
import { LoginContextService } from '@mpk/shared/data-access';
import { type SettingsFormPlugin, type SettingsFormPluginComponent } from '@mpk/shared/util';

import { SapOdataPurchaseRequisitionsPermissions } from '../permissions';

/**
 * Factory to create the plugin for editing the SAP OData purchase requisition client settings.
 */
export function createTransferPurchaseRequisitionClientSettingsPlugin(): SettingsFormPlugin {
  const loginContextService = inject(LoginContextService);

  return {
    settingsId: 'SapOdataClientSettings',
    displayName: 'SAP BANF-Anlage',
    description: 'API-Konfiguration des SAP Services zur Anlage von Bestellanforderungen (BANF)',
    isEnabled: loginContextService.permission(SapOdataPurchaseRequisitionsPermissions.ManageSettings),
    async getComponentDetails(
      parentInjector: EnvironmentInjector,
    ): Promise<LazyComponentDetails<SettingsFormPluginComponent>> {
      const { TransferPurchaseRequisitionClientSettingsEditComponent } = await import('../components');

      return {
        component: TransferPurchaseRequisitionClientSettingsEditComponent,
        environmentInjector: parentInjector,
      };
    },
  };
}
