import { permissions } from '@mpk/shared/domain';

enum PurchaseRequisitionsPermissionsEnum {
  None = 0,

  /**
   * Approve own purchase requisitions.
   */
  ApproveOwnPurchaseRequisitions = 1,

  /**
   * View all purchase requisitions of own cost centers.
   */
  ViewPurchaseRequisitionsByOwnCostcenters = 2,

  /**
   * View and approve all purchase requisitions of own cost centers.
   */
  ViewAndApprovePurchaseRequisitionsByOwnCostcenters = 4 |
    ViewPurchaseRequisitionsByOwnCostcenters |
    ApproveOwnPurchaseRequisitions,

  /**
   * View all purchase requisitions.
   */
  ViewAllPurchaseRequisitions = 8 | ViewPurchaseRequisitionsByOwnCostcenters,

  /**
   * View and approve all purchase requisitions.
   */
  ViewAndApproveAllPurchaseRequisitions = 16 |
    ViewAllPurchaseRequisitions |
    ViewAndApprovePurchaseRequisitionsByOwnCostcenters,
}

export const PurchaseRequisitionsPermissions = permissions(PurchaseRequisitionsPermissionsEnum, {
  featureId: 'PurchaseRequisitions',
});
