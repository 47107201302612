import { EnvironmentInjector, createEnvironmentInjector, inject } from '@angular/core';

import { LazyComponentDetails } from '@core/shared/util';
import { ConditionsPermissions } from '@mp/contract-management/conditions/feature';
import { ComponentPluginComponent } from '@mp/shared/component-plugins/util';
import { LoginContextService } from '@mpk/shared/data-access';
import type { PlatformSupplierDetailsTabPlugin, PlatformSupplierDetailsTabPluginInput } from '@mpsm/shared/util';

export function createPlatformSupplierDetailsTabPlugin(): PlatformSupplierDetailsTabPlugin {
  const loginContextService = inject(LoginContextService);

  return {
    label: 'Konditionen',
    isEnabled: loginContextService.permission(ConditionsPermissions.ViewConditions),
    async getComponentDetails(
      parentInjector: EnvironmentInjector,
    ): Promise<LazyComponentDetails<ComponentPluginComponent<PlatformSupplierDetailsTabPluginInput>>> {
      const { PlatformSupplierConditionsComponent } = await import('../components');
      const { providePlatformSupplierConditionsDataAccess } = await import(
        '@mp/contract-management/conditions/platform-suppliers/data-access'
      );

      return {
        component: PlatformSupplierConditionsComponent,
        environmentInjector: createEnvironmentInjector(providePlatformSupplierConditionsDataAccess(), parentInjector),
      };
    },
  };
}
