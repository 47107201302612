import { Injectable, Signal, computed, inject } from '@angular/core';

import { ProfileFacade } from '@mp/auth/profile/data-access';
import { InsightsOrganizationInfoService } from '@mp/shared/app-insights/util';

/**
 * The implementation of InsightsOrganizationInfoService that provides the currently selected organization name.
 */
@Injectable()
export class AppInsightsOrganizationInfoService extends InsightsOrganizationInfoService {
  readonly organizationName: Signal<string | null> = computed(
    () => this.profileFacade.profile()?.activeOrganization?.name ?? null,
  );

  private readonly profileFacade: ProfileFacade = inject(ProfileFacade);
}
