import { EnvironmentInjector, createEnvironmentInjector, inject } from '@angular/core';

import { LazyComponentDetails } from '@core/shared/util';
import { ContractsPermissions } from '@mp/contract-management/common/feature';
import { ComponentPluginComponent } from '@mp/shared/component-plugins/util';
import { LoginContextService } from '@mpk/shared/data-access';
import type { PlatformSupplierDetailsTabPlugin, PlatformSupplierDetailsTabPluginInput } from '@mpsm/shared/util';

export function createPlatformSupplierDetailsTabPlugin(): PlatformSupplierDetailsTabPlugin {
  const loginContextService = inject(LoginContextService);

  return {
    label: 'Verträge',
    isEnabled: loginContextService.permission(ContractsPermissions.ViewContracts),
    async getComponentDetails(
      parentInjector: EnvironmentInjector,
    ): Promise<LazyComponentDetails<ComponentPluginComponent<PlatformSupplierDetailsTabPluginInput>>> {
      const { PlatformSupplierContractsComponent } = await import('../components');
      const { providePlatformSupplierContractsDataAccess } = await import(
        '@mp/contract-management/contracts/platform-suppliers/data-access'
      );

      return {
        component: PlatformSupplierContractsComponent,
        environmentInjector: createEnvironmentInjector(providePlatformSupplierContractsDataAccess(), parentInjector),
      };
    },
  };
}
