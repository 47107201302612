import { permissions } from '@mpk/shared/domain';

/**
 * Permissions for the
 */
enum NewsPermissionsEnum {
  None = 0,

  /**
   * News can be created in my organization
   */
  CreateNewsForOwnOrganization = 1,

  /**
   * News can be created in all organizations
   */
  CreateNewsForAllOrganizations = 2 | CreateNewsForOwnOrganization,
}

export const NewsPermissions = permissions(NewsPermissionsEnum, {
  featureId: 'News',
});
