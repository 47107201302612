import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { AddressesPermissions } from '@mp/organization-master-data/addresses/feature';
import { CostCentersPermissions } from '@mp/organization-master-data/cost-centers/feature';
import { createCurrentOrganizationUserDetailsTabPlugin } from '@mp/organization-master-data/user-cost-centers-assignment/feature';
import { provideModuleInfo } from '@mp/shared/util';
import { provideModulePermissions } from '@mpk/shared/data-access';
import { CURRENT_ORGANIZATION_USER_DETAILS_TAB_PLUGIN } from '@mpk/shared/util';

const moduleName = 'OrganizationMasterData';

export function provideOrganizationMasterDataFeature(): EnvironmentProviders[] {
  return [
    provideModuleInfo(moduleName, {
      displayName: 'Organisationsstammdaten',
    }),
    provideModulePermissions(moduleName, AddressesPermissions, CostCentersPermissions),
    makeEnvironmentProviders([
      {
        provide: CURRENT_ORGANIZATION_USER_DETAILS_TAB_PLUGIN,
        multi: true,
        useFactory: createCurrentOrganizationUserDetailsTabPlugin,
      },
    ]),
  ];
}
