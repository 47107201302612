<mp-upload-form
  *ngrxLet="vm$ as vm"
  [uploadingProgress]="vm.uploadProgress"
  [status]="vm.fileUploadingStatus"
  [disabled]="disabled"
  [allowMultipleUploads]="false"
  [acceptedFileTypes]="acceptedFileTypes"
  [maxFileBytes]="maxFileBytes"
  (filesChange)="onFilesChange($event)"
>
  <mp-file-upload-label
    buttonLabel="Produktdaten importieren"
    iconName="post_add"
    [status]="vm.fileUploadingStatus"
    [acceptedFileTypes]="acceptedFileTypes"
    [errorMessage]="vm.errorMessage"
  />
</mp-upload-form>
